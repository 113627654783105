import { CloudinaryFixed, CloudinaryFluid } from './CloudinaryImg';
import { ShopifyFluid } from './ShopImg';

const transformProduct = (raw) => {
  const product = { ...raw };

  product.legal = product.customfields?.legal;
  product.moreInfos = product.customfields?.moreInfos;

  product.recommendations = (product.recommendations || [])
    .filter(p => (p.tags || []).indexOf('old') === -1)
    .slice(0, 4).map(p => transformProduct(p));


  product.features = (product.customfields?.features || [])
    .map(field => {
      return {
        icon: field.icon && field.icon.length ? CloudinaryFixed(field.icon[0], { width: 40, height: 30 }) : undefined,
        text: field.text
      }
    }).filter(f => f.icon || f.text);

  product.commonImages = (product.customfields?.moreImages || [])
    .filter(item => item.image)
    .map((item, idx) => {
      return {
        id: `common-${idx}`,
        fluid: item.image && item.image.length ? CloudinaryFluid(item.image[0], { maxWidth: 660 }) : undefined,
        thumbnail: item.image && item.image.length ? CloudinaryFluid(item.image[0], { maxWidth: 80 }) : undefined,
        altText: item.text
      }
    });
  product.contentBlocks = (product?.customfields?.contentBlock || [])
    .map((item, idx) => {
      return {
        id: `block-${idx}`,
        title: item.title,
        text: item.text,
        subhead: item.subhead,
        isTextLeft: item.textLeft === 'true',
        video: item.video ? item.video : undefined,
        image: item.image && item.image.length ? CloudinaryFluid(item.image[0], { maxWidth: 660 }) : undefined,
      };
    })

  product.rootCategory = (product.collections || [])
    .find(c => c.customfields.isRootCategory);

  product.rangeCategory = (product.collections || [])
    .find(c => c.customfields?.isProductRange);

  product.variants = (product.variants || []).map(variantRaw => {
    const variant = { ...variantRaw };

    const images = [];

    if (variant.image?.originalSrc) {
      images.push({
        fluid: ShopifyFluid(variant.image?.originalSrc, { maxWidth: 660 }, variant.image?.localFile?.childImageSharp.fluid),
        thumbnail: ShopifyFluid(variant.image?.originalSrc, { maxWidth: 80 }, variant.image?.localFile?.childImageSharp.fluid),
        id: variant.image?.id,
        altText: variant.image?.altText,
        originalSrc: variant.image?.originalSrc,
      })
    }

    (variant.customfields?.moreImages || []).forEach((item, idx) => {
      if (!item.image || !item.image.length) {
        return;
      }
      images.push({
        fluid: CloudinaryFluid(item.image[0], { maxWidth: 660 }),
        thumbnail: CloudinaryFluid(item.image[0], { maxWidth: 80 }),
        id: `more-image-${idx}`,
        altText: item.text,
      });
    });

    const result = {
      ...variant,
      thumbnail: images.length ? images[0] : undefined,
      images,
    };

    delete result.image;
    delete result.metafields;
    delete result.customfields;
    return result;
  });

  delete product.metafields;
  delete product.customfields;

  return product;
}

export default transformProduct;

import React from 'react';
import styled from 'styled-components';

import { Icon } from './icons';
import LogoImg from '../images/logo.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.4px;

  .text {
    margin-top: 10px;
  }

  &.white {
    .text {
      color: #FFF;
    }
  }
`;

const Logo = ({
  className
}) => {
  return (
    <Wrapper
      className={className}
    >
      <Icon
        src={LogoImg}
        height="50px"
        alt="Ipone"
      />
      {/* <div className="text">100% MOTORCYCLE</div> */}
    </Wrapper>
  );
}

export default Logo;

const getImageResolution = (fullUrl, { width, height }, format) => {
  if (!fullUrl) {
    return undefined;
  }

  const params = [
    'c_fit'
  ];

  if (width) {
    params.push(`w_${Math.round(width)}`);
  }

  if (height) {
    params.push(`h_${Math.round(height)}`);
  }

  if (format) {
    params.push(`f_${format}`);
  }

  return `${fullUrl}${params.join(',')}`;
};

const multiplyRes = (res, ratio) => {
  return Object.keys(res).reduce((acc, key) => {
    acc[key] = Math.round(res[key] * ratio);
    return acc;
  }, {});
}

const pRatios = [1, 2];
const breakpoints = [0.25, 0.5, 1.5, 2];

export const CloudinaryFixed = (infos, res) => {
  const result = { ...infos };
  result.aspectRatio = result.aspect_ratio;
  const url = result.cloudinary_src;
  delete result.cloudinary_src;
  delete result.aspect_ratio;

  const size = { ...res };
  if (!size.width && !size.height) {
    throw Error('ShopifyFixed no width or height specified');
  }

  if (!size.width) {
    size.width = Math.round(size.height * result.aspectRatio);
  }

  if (!size.height) {
    size.height = Math.round(size.width / result.aspectRatio);
  }

  result.src = getImageResolution(url, size);
  result.srcSet = pRatios
    .map(pr => `${getImageResolution(url, multiplyRes(size, pr))} ${pr}x`)
    .join(",\n");
  result.srcSetWebp = pRatios
    .map(pr => `${getImageResolution(url, multiplyRes(size, pr), 'webp')} ${pr}x`)
    .join(",\n");
  result.width = size.width;
  result.height = size.height;

  return result;
};

export const CloudinaryFluid = (infos, res) => {
  const result = { ...infos };

  const url = result.cloudinary_src;
  result.aspectRatio = result.aspect_ratio;
  delete result.cloudinary_src;
  delete result.aspect_ratio;

  const size = { };
  if (!res.maxWidth && !res.maxHeight) {
    throw Error('ShopifyFluid no maxWidth or maxHeight specified');
  }

  if (res.maxWidth) {
    size.width = res.maxWidth;
    size.height = Math.round(size.width * result.aspectRatio);
  } else if (res.maxHeight) {
    size.height = res.maxHeight;
    size.width = size.height / result.aspectRatio;
  }

  result.src = getImageResolution(url, size);
  result.srcSet = breakpoints
    .map(pr => `${getImageResolution(url, multiplyRes(size, pr))} ${size.width * pr}w`)
    .join(",\n");
  result.srcSetWebp = breakpoints
    .map(pr => `${getImageResolution(url, multiplyRes(size, pr), 'webp')} ${size.width * pr}w`)
    .join(",\n");
  
  if (!result.sizes) {
    result.sizes = `(max-width: ${size.width}px) 100vw, ${size.width}px`;
  }
  return result;
}
import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  background-color: #fafafa;
  padding: 25px 0;
  font-size: 15px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    text-align: center;
    margin-bottom: 20px;
  }

  .form {
    display: flex;
    

    input {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 10px 20px;
      border: solid 2px #dadada;
      background-color: #ffffff;
      outline: none;
      flex: 1;
      border-right-width: 0;

      transition: border-color .3s;
      
      &:focus {
        border-color: #949494;
      }

      &.added {
        border-color: #808080;
        ::placeholder {
          color: #000;
          font-weight: 500;
        }
      }
    }

    button {
      background-color: #c80f05;
      color: #FFF;
      padding: 5px 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      transition: background-color .3s;

      &:hover {
        background-color: #b30e05;
      }
    }
  }

  @media ${device.tablet} {
    .container {
      flex-direction: row;
    }

    .text {
      margin-right: 40px;
      margin-bottom: 0px;
      text-align: left;
    }

    .form {
      flex: 0 0 40%;
    }
  }
`;
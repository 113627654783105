import React from 'react';

import {
  Wrapper,
} from './footer.styles';

const Footer = ({ menu }) => {

  return (
    <Wrapper>
      
    </Wrapper>
  );
};

export default Footer;

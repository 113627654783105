import { useState, useEffect } from 'react';

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== 'undefined';


export default (staticData) => {
  const [data, setData] = useState();

  useEffect(() => {
    const process = async() => {
      if (IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__) {
        const { mergePrismicPreviewData } = await import(
          /* webpackChunkName: "gatsby-source-prismic" */ 'gatsby-source-prismic'
        );

        setData(
          mergePrismicPreviewData({
            staticData,
            previewData: window.__PRISMIC_PREVIEW_DATA__
          })
        );
      }
    }
    process();
  }, [staticData]);
  return data || staticData;
}

const getLocale = lang => `${lang}`.toLowerCase().split('-').shift();
const defaultLocale = 'fr';
const locales = ['fr'];
const localeSettings = {
  'fr': {
    shop: false,
  },
  'en': {
    shop: false,
  },
  'es': {
    shop: false,
  }
}

const getLocaleSettings = (locale = defaultLocale) => {
  return localeSettings[locale] || localeSettings[defaultLocale];
}

const getEshopUrl = (locale = defaultLocale) => {
  const lang = getLocale(locale);
  return `/${lang}/eshop/`;
}

const getProductUrl = (handle, locale = defaultLocale, variant) => {
  const lang = getLocale(locale);
  let section;
  const variantHandle = (variant && variant.viscosity ? `-${variant.viscosity}` : '').toLowerCase().replace(' ', '-');
  switch (locale) {
    case 'fr':
      section = 'produit';
      break;
    default:
      section = 'product';
  }

  let cleanHandle = `${(handle || '')}`.trim();

  if (cleanHandle.endsWith('-1')) {
    cleanHandle = cleanHandle.slice(0, -2);
  }

  return `/${lang}/eshop/${section}/${cleanHandle}${variantHandle}`;
}

const getCategoryUrl = (handle = '', locale = defaultLocale) => {
  if (handle === '') {
    return getEshopUrl(locale);
  }
  const lang = getLocale(locale);
  let part = 'categorie';

  if (lang === 'en') {
    part = 'category';
  }

  if (lang === 'es') {
    part = 'categoria'
  }
  return `/${lang}/eshop/${part}/${handle}`;
}

const getProductPremiumUrl = (handle, locale = defaultLocale) => {
  const lang = getLocale(locale);
  return `/${lang}/eshop/premium/${handle}`;
}

const getConfiguratorUrl = (handle, locale = defaultLocale) => {
  const configuratorPages = {
    'fr': 'trouver-la-bonne-huile-moto',
    'en': 'find-the-right-motorcycle-oil',
    'es': 'encuentra-el-mejor-aceite-para-tu-moto'
  };

  let url = `/${locale}/${configuratorPages[locale]}`;

  if (handle) {
    url = `${url}/${handle}`;
  }

  return url;
}

const getArticleUrl = (handle, locale = defaultLocale) => {
  const lang = getLocale(locale);
  let section;
  switch (locale) {
    case 'es':
      section = 'articulos';
      break;
    default:
      section = 'articles';
  }

  let url = `/${lang}/${section}`;

  if (handle) {
    url = `${url}/${handle}`;
  }

  return url;
}

const getLegalLink = (locale = defaultLocale) => {
  if (locale === 'en') {
    return getUrl('general-conditions-of-sale', locale);
  }

  if (locale === 'es') {
    return getUrl('condiciones-generales-de-venta', locale);
  }

  return getUrl('conditions-generales-de-vente', locale);
}

const getCreditsLink = (locale = defaultLocale) => {
  if (locale === 'en') {
    return getUrl('credits', locale);
  }

  if (locale === 'es') {
    return getUrl('creditos', locale);
  }

  return getUrl('credits', locale);
}

const getUrl = (handle, locale = defaultLocale) => {
  const lang = getLocale(locale);
  let path = handle;
  if (path.indexOf('/') !== 0) {
    path = `/${path}`;
  }

  if (path === '/' && locale === defaultLocale) {
    return '/';
  }

  return `/${lang}${path}`;
};

const resolvePrismicLink = (doc) => {
  const locale = getLocale(doc.lang || 'fr');

  if (doc.type === 'homepage' || doc.type === 'widgets' || doc.type === 'menu') {
    return locale === defaultLocale ? '/' : `/${getLocale(doc.lang)}`;
  }

  if (doc.type === 'homearticle') {
    return getArticleUrl('', locale);
  }

  if (doc.type === 'eshophome') {
    return getEshopUrl(locale);
  }

  if (doc.type === 'premium') {
    return getProductPremiumUrl(doc.uid, locale);
  }

  if (doc.type === 'page' || doc.type === 'partner-page' || doc.type === 'productrange_categories') {
    return getUrl(doc.uid, locale);
  }
  // console.warn('!!! Unprocessed prismic link', doc.type, locale);

  return getUrl(`/${doc.type}/${doc.uid}`, locale);
}

const getBrowserLocale = () => {
  if (typeof window !== 'object') {
    return defaultLocale;
  }

  return (navigator.languages || []).map(longCode => {
    const parts = longCode.split('-');
    return parts[0];
  }).find(code => {
    return locales.indexOf(code) !== -1;
  }) || 'en';
};

module.exports = {
  getProductUrl,
  getCategoryUrl,
  getProductPremiumUrl,
  getArticleUrl,
  getEshopUrl,
  getLocale,
  getLegalLink,
  getCreditsLink,
  locales,
  defaultLocale,
  getUrl,
  resolvePrismicLink,
  getLocaleSettings,
  getConfiguratorUrl,
  getBrowserLocale
};

import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #FFF;
  padding: 15px 10px;

  &.show {
    display: fixed;
  }
  
  .container {
    text-align: center;
    font-size: 11px;
  }

  .text {
    display: block;
    margin-bottom: 10px;
  }

  .actions {
    display: inline-block;
    margin-left: 20px;

    .main {
      background-color: #c80f05;
      color: #FFF;
      padding: 5px 10px;
      transition: background-color .3s;
      font-weight: bold;

      &:hover {
        background-color: #b30e05;
      }
    }

    a {
      display: inline-block;
      margin-left: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media ${device.tablet} {
    .container {
      font-size: 13px;
    }

    .text {
      display: inline-block;
      margin-bottom: 0;
    }
  }
`;
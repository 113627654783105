import { defineMessages } from 'react-intl';

export default defineMessages({
  cookiesText: {
    id: 'cookies.text',
    defaultMessage: 'Les cookies assurent le bon fonctionnement de nos services. En utilisant ces derniers, vous acceptez l\'utilisation des cookies.',
  },
  cookiesOK: {
    id: 'cookies.ok',
    defaultMessage: 'OK'
  },
  cookiesInfos: {
    id: 'cookies.info',
    defaultMessage: 'En savoir plus'
  },
  newsletterTitle: {
    id: 'newsletter.title',
    defaultMessage: `Ne ratez pas nos bons plans {lineBreak} inscrivez-vous à la newsletter :`
  },
  newsletterPlaceholder: {
    id: 'newsletter.placeholder',
    defaultMessage: 'Ajoutez votre email...'
  },
  newsletterThankYou: {
    id: 'newsletter.thankyou',
    defaultMessage: 'Merci pour votre inscription !'
  },
  newsletterSubmit: {
    id: 'newsletter.submit',
    defaultMessage: 'S\'inscrire'
  }
});

import React, { useState, useEffect, useCallback } from 'react';
// import client from './client';
// import queries from './queries';

const isBrowser = typeof window !== 'undefined';
const savedToken = isBrowser
                      ? JSON.parse(localStorage.getItem('shopify_token'))
                      : null;
const existingCheckoutID = isBrowser
                      ? localStorage.getItem('shopify_checkout_id')
                      : null;

const initialContext = {
  adding: false,
  removing: false,
  updating: false,
  checkout: {
    lineItems: [],
  },
  auth: {
    logged: !!savedToken,
    accessToken: undefined,
    expiresAt: undefined,
    ...savedToken
  },
  user: undefined,
};

let ensuringClientPromise;
let ensuringCheckoutPromise;

let client = null;

const getCheckoutOrders = () => {
  try {
    return JSON.parse(localStorage.getItem('shopify_checkout_items') || []);
  } catch (e) {
    return [];
  }
}

const resetCheckoutOrders = () => {
  localStorage.removeItem('shopify_checkout_items');
}

const appendCheckoutOrders = (variantId) => {
  const orders = getCheckoutOrders();
  const newOrders = [
    ...orders,
    variantId,
  ];
  localStorage.setItem('shopify_checkout_items', JSON.stringify(newOrders));
}

const removeCheckoutOrders = (variantId) => {
  const orders = getCheckoutOrders().filter(v => v !== variantId);
  localStorage.setItem('shopify_checkout_items', JSON.stringify(orders));
  return orders;
}

const initClient = async () => {
  const { default: Shopify } = await import(
    /* webpackChunkName: "shopify-buy" */ 'shopify-buy'
  );

  client = Shopify.buildClient({
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    domain: 'checkout.ipone.com',
    apiVersion: '2020-07',
  });
  // console.log('[Shopify] Init - Client ready');

  return client;
};

const initCheckout = async () => {
  let newCheckout;

  if (existingCheckoutID) {
    try {
      newCheckout = await client.checkout.fetch(existingCheckoutID)
      // Make sure this cart hasn’t already been purchased.
      if (newCheckout.completedAt) {
        newCheckout = null;
      }
    } catch (e) {
      localStorage.setItem('shopify_checkout_id', null);
    }
  }

  if (!newCheckout) {
    newCheckout = await client.checkout.create();
    localStorage.setItem('shopify_checkout_id', newCheckout.id);
    resetCheckoutOrders();
  }
  // console.log('[Shopify] Init - Checkout ready', newCheckout);
  return newCheckout;
}

const ensureClient = async () => {
  if (client) {
    return client;
  }

  if (ensuringClientPromise) {
    // console.log('Already initializing, waiting for promise');
    return ensuringClientPromise;
  }
  ensuringClientPromise = initClient();

  ensuringClientPromise.then(() => {
    ensuringClientPromise = null;
  });

  return ensuringClientPromise;
};

const ensureCheckout = async (checkout) => {
  if (checkout?.id) {
    return checkout;
  }

  if (ensuringCheckoutPromise) {
    // console.log('Already initializing, waiting for promise');
    return ensuringCheckoutPromise;
  }
  ensuringCheckoutPromise = initCheckout();

  ensuringCheckoutPromise.then(() => {
    ensuringCheckoutPromise = null;
  });
  return ensuringCheckoutPromise;
};

const addToCart = async (inCheckout, update, variantId, quantity) => {
  await ensureClient();
  const checkout = await ensureCheckout(inCheckout);
  // console.log('[Shopify] AddToCart params', variantId, quantity);
  if (variantId === '' || !quantity) {
    console.error('Both a size and quantity are required.')
    return
  }

  update(prevState => ({
    ...prevState,
    adding: true
  }));

  const lineItemsToUpdate = [
    {
      variantId,
      quantity: parseInt(quantity, 10)
    },
  ];

  appendCheckoutOrders(variantId);

  const newCheckout = await client.checkout
    .addLineItems(checkout.id, lineItemsToUpdate);

  update(prevState => ({
    ...prevState,
    adding: false,
    checkout: newCheckout
  }));
}

const fetchProduct = async (productId) => {
  await ensureClient();
  return client.product.fetch(productId);
}
const removeLineItem = async (inCheckout, update, line) => {
  await ensureClient();
  const checkout = await ensureCheckout(inCheckout);

  update(prevState => ({
    ...prevState,
    removing: true
  }));
  
  if (line?.variant?.id) {
    removeCheckoutOrders(line?.variant?.id);
  }

  const newCheckout = await client.checkout
    .removeLineItems(checkout.id, [line?.id]);

    update(prev => ({
    ...prev,
    removing: false,
    checkout: newCheckout
  }));
}

const updateLineItem = async (inCheckout, update, lineItemID, quantity) => {
  await ensureClient();
  const checkout = await ensureCheckout(inCheckout);

  update(prevState => ({
    ...prevState,
    updating: true
  }));

  const newCheckout = await client.checkout
    .updateLineItems(checkout.id, [{ id: lineItemID, quantity }]);

    update(prev => ({
    ...prev,
    updating: false,
    checkout: newCheckout
  }));
}

export const StoreContext = React.createContext(initialContext);

const ContextProvider = ({ children }) => {
  const [store, updateStore] = useState(initialContext);

  useEffect(() => {
    const initializeCheckout = async () => {
      if (!isBrowser || !existingCheckoutID) {
        return;
      }

      // console.log('[Shopify] Has existing checkout, init early');
      await ensureClient();
      const checkout = await ensureCheckout();
      updateStore(prev => ({
        ...prev,
        checkout
      }));
    }

    initializeCheckout();
  }, []);

  const cartOrders = getCheckoutOrders();

  const state = {
    ...store,
    loading: store.adding || store.removing || store.updating,
    ready: !!client,
    addToCart: useCallback((...props) => {
        return addToCart(store.checkout, updateStore, ...props);
      },
      [updateStore, store.checkout]
    ),
    removeLineItem: useCallback((...props) => {
        return removeLineItem(store.checkout, updateStore, ...props);
      },
      [updateStore, store.checkout]
    ),
    updateLineItem: useCallback((...props) => {
      return updateLineItem(store.checkout, updateStore, ...props);
      },
      [updateStore, store.checkout]
    ),
    fetchProduct,
    cartItems: store.checkout.lineItems.sort((a, b) => {
      const aPos = cartOrders.indexOf(a?.variant?.id);
      const bPos = cartOrders.indexOf(b?.variant?.id);
      return aPos - bPos;
    })
  };

  // console.log('[Shopify] Hook update', state);

  return (
    <StoreContext.Provider
      value={state}
    >
      {children}
    </StoreContext.Provider>
  )
}
export default ContextProvider;
import styled from 'styled-components';

import { device } from '../utils';

export const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;

  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color .6s;
  backdrop-filter: saturate(110%) blur(2px);
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.15);

  &.hide-shadow {
    box-shadow: none;
  }

  &:hover, &.is-floating {
    background-color: rgba(255, 255, 255, 0.9);
  }

  &.debug {
    background-color: rgba(255, 255, 255, 0.2);
    .container {
      background-color: rgba(200, 100, 0, 0.4);
    }
  }

  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-right: 0;
  }

  .logo {
    padding: 25px 0;
    position: relative;

    span {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
    }

    a {
      display: block;
      line-height: 0;
    }
  }
`;

export const BackButton = styled.button`
  position: absolute;
  left: 0;
  border: none;
  margin: 0px 0px 0px 20px;
  border-radius: 30px;
  background-color: rgb(200, 15, 5);
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: rgb(255, 255, 255);
  line-height: 1.2em;
  transition: background-color 300ms ease 0s;
  -webkit-appearance: button;
  font-weight: 500;
  font-size: 16px;
  
  &:hover {
    background-color: rgb(175, 13, 4);
  }
`;

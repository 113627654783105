import { createGlobalStyle } from 'styled-components';

import { device } from '../utils';

export default createGlobalStyle`
  :root {
    --header-height: 100px;
  }

  body {
    /* font-family: 'Montserrat', Helvetica, Arial, sans-serif; */
    font-family: 'Roboto';
    margin: 0;
  }

  html, body {
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 16px;

    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    &, a {
      color: #111111;
    }
  }

  a {
    text-decoration: none;
    outline: none;
  }

  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0)!important;
  }

  .gatsby-image-wrapper img {
    /* transition: opacity .5s ease 0s!important; */
    font-size: 0;
  }

  button {
    cursor: pointer;
    outline: none;
  }

  button.clear {
    background: none;
    border: 0;
    appearance: none;
  }

  .button {
    font-family: 'Montserrat';
    display: block;
    font-weight: 900;
    font-style: italic;
    color: #000;
    text-align: center;
    padding: 10px 20px;
    margin: 15px 0;
  
    &.dark {
      background-color: #000;
      color: #FFF;
    }
  }

  .bigtitle {
    font-family: 'Montserrat';
    font-size: 25px;
    letter-spacing: 0.38px;
    line-height: 0.9em;
    margin: 10px 0;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;

    @media ${device.laptop} {
      font-size: 30px;
    }

    &.white {
      color: #FFF;
    }
  }

  .redtitle {
    font-size: 24px;
    color: #E0261D;
    line-height: 1em;
    margin: 20px 0 10px;
    text-transform: uppercase;
    font-weight: lighter;
  }

  .product-image {
    img {
      /* filter: saturate(1.01) contrast(1.01) brightness(1.01); */
    }
  }

  .product-grid {
    display: grid;
    grid-gap: 20px 20px;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    box-sizing: border-box;

    @media ${device.laptop} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media ${device.laptopM} {
      grid-gap: 40px 60px;
    }

    &.three {
      @media ${device.laptop} {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
  /* h2 {
    font-size: 24px;
    color: #E0261D;
    line-height: 1em;
    margin: 20px 0 10px;
    text-transform: uppercase;
    font-weight: lighter;
  }

  h3 {
    font-size: 33px;
    letter-spacing: 0.38px;
    line-height: 0.9em;
    margin: 10px 0;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;

    @media ${device.laptop} {
      font-size: 40px;
    }

    &.white {
      color: #FFF;
    }
  } */

  p {
    line-height: 1.3em;
  }

  #gatsby-noscript {
    background: #000;
    width: 100%;
    display: block;
    padding: 10px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
  }


  .mobile-only {
    @media ${device.tablet} {
      display: none!important;
    }
  }

  .h-mobile {
    @media ${device.tabletDown} {
      display: none!important;
    }
  }

  .h-tablet {
    @media ${device.laptopDown} and ${device.tablet} {
      display: none!important;
    }
  }

  .h-laptop {
    @media (min-width: 1025px) {
      display: none!important;
    }
  }

  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    ${device.laptop} {
      padding: 0 40px;
    }
  }

  .bbox {
    display: inline-block;
    background: #000;
    padding: 7px;
    transform: skew(-15deg);

    &.title {
      padding: 10px 15px 5px 15px;
    }

    & > * {
      display: inline-block;
      transform: skew(15deg);
      color: #FFF;
      font-weight: 900;
      font-style: italic;
      font-family: 'Montserrat';
      text-transform: uppercase;
    }
  }

  .menu-link {
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    padding: 5px 0;

    &:before, &:after {
      content: "";
      position: absolute;
      left: 0px;
      z-index: 9;
      height: 1px;
      width: 100%;
      top: 0px;
      background-color: #000;
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      transform: scaleX(0);
      transform-origin: left;
    }

    &:after {
      top: auto;
      bottom: 0px;
      transform-origin: right;
    }

    &:hover, &.hover {
      &[href] {
        &:after, &:before {
          transform: scaleX(1) scaleY(1);
        }
      }
    }

    &.white {
      color: #FFF;
      &:before, &:after {
        background-color: #FFF;
      }
    }
  }

  .simple-link {
    position: relative;
    font-size: 14px;
    font-weight: 500;

    @media ${device.laptop} {
      font-size: 16px;
    }

    &:after {
      content: '>';
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
      margin-left: 3px;
      transform: scaleX(0.7) scaleY(1.1);
      transition: transform 300ms;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0px;
      z-index: 9;
      height: 1px;
      width: 100%;
      bottom: -2px;
      background-color: #FFF;
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      transform: scaleX(0);
      transform-origin: left;
    }

    &.noicon {
      &:after {
        display: none;
      }
    }

    &.red {
      color: #c80f05;

      &:before {
        background-color: rgb(224, 38, 29);
      }
    }

    &.black {
      color: #000;

      &:before {
        background-color: #000;
      }
    }

    &.block {
      border-radius: 30px;
      background-color: #c80f05;
      padding: 7px 20px;
      transition: background-color 300ms;

      &:before {
        display: none;
      }

      &:hover, &.hover {
        background-color: #AF0D04;
      }
    }

    &:hover, &.hover {
      &:after {
        transform: scaleX(0.7) scaleY(1.1) translateX(3px);
      }

      &:before {
        transform: scaleX(1);
      }
    }
  }
`;

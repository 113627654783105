import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import { useLocation, navigate } from '@reach/router';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import Logo from '../components/Logo';

import { getUrl, getLocaleSettings, isClient } from '../utils';


import {
  Wrapper,
  BackButton,
} from './header.styles';


const checkIsFloating = y => y > 90;
const useIsFloating = () => {
  const [isFloating, setIsFloating] = useState(checkIsFloating(isClient ? window.pageYOffset : 0));
  
  useEffect(() => {
    const handler = () => {
      const newState = checkIsFloating(window.pageYOffset);
      if (newState === isFloating) {
        return;
      }
      setIsFloating(newState);
    };
    window.addEventListener('scroll', handler, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [isFloating]);

  return isFloating;
}

const Header = ({
  displayShadow = true,
  alternateLocales = [],
  menu = [],
  cartBanner,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isFloating = useIsFloating();

  const settings = getLocaleSettings(intl.locale);
  console.log(location);
  // close menu if location change
  useEffect(() => {
    return () => {
      isMenuOpen && setIsMenuOpen(false);
    };
  }, [location, isMenuOpen]);

  return (
    <Wrapper
      className={classnames({
        'is-floating': isFloating,
        'is-menu-open': isMenuOpen,
        'hide-shadow': true, //!displayShadow,
      })}
    >
      <div className="container">
        <BackButton
          onClick={() => navigate(-1)}
        >
          &lt; Retour
        </BackButton>
        <div
          className="logo"
        >
          <Link
            to={getUrl('/', intl.locale)}
            aria-label="Ipone"
          >
            <span>Ipone</span>
            <Logo
              className={classnames({
                white: isMenuOpen
              })}
            />
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;


import React from 'react';

import { createBreakpoint } from 'react-use';
import { default as _Product } from './Product';
import { default as _Category } from './Category';

export { device } from './responsive';
export * from './links';
export { ShopifyFixed, ShopifyFluid } from './ShopImg';
export { CloudinaryFixed, CloudinaryFluid } from './CloudinaryImg';
export { default as Imgix } from './Imgix';

export const isClient = typeof window === 'object';
export const useBreakpoint = createBreakpoint({ laptopL: 1440, laptopM: 1200, laptop: 1024, tablet: 768, mobile: 1, ssr: 0 });
export const rem = px => `${Math.round(px / 16 * 1000) / 1000}rem`;

export const splitLines = (text = '') => (text || '')
  .split(/\r\n|[\n\r\u2028\u2029]/g);

export const nl2br = (text = '') => splitLines(text)
  .filter(a => a).map((item, key) => (
    <React.Fragment key={key}>{item}<br /></React.Fragment>
  ));

export const regroup = (obj) => {
  return Object.keys(obj).reduce((res, field) => {
    const parts = field.split('_');
    const prefix = parts.shift();
    
    const newField = parts.map((p, idx) => idx === 0 ? p : p.charAt(0).toUpperCase() + p.slice(1)).join('');

    if (!newField) {
      res[field] = obj[field];
      return res;
    }

    let node = res[prefix];
    if (!node) {
      res[prefix] = node = {};
    }

    node[newField] = obj[field];

    return res;
  }, {});
}

export const truncate = (str, length = 200, ending = '...') => {
  if (str.length <= length) {
    return str;
  }
  return str.substring(0, length - ending.length) + ending;
};

export const stripHtml = (str) => `${str}`.replace(/<[^>]*>?/gm, '');

export const Product = _Product;
export const Category = _Category

export const parseDate = (date) => {
  if(!date) return null;
  var correctIso8601Date = (date.length === 24) 
    ? `${date.substring(0, 22)}:${date.substring(22, 24)}`
    : date;
  return new Date(correctIso8601Date);
};

export const camelize = (text, separator = '-') => text
   .split(separator)
   .filter(cur => cur)
   .reduce((acc, cur, idx) => idx === 0 ? `${acc}${cur}` : `${acc}${cur.charAt(0).toUpperCase() + cur.slice(1)}`, '')

export const youtubeParser = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11)? match[7] : false;
}

export const isToday = (td) => {
  const d = new Date();
  return td.getDate() === d.getDate() && td.getMonth() === d.getMonth() && td.getFullYear() === d.getFullYear();
}

export const isWeekend = () => {
  const d = new Date().getDay();
  return (d === 6) || (d === 0);
}
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import classnames from 'classnames';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { Helmet } from "react-helmet";
import 'normalize.css';
import { IntlProvider } from 'react-intl';
import { useLocation } from "@reach/router";

import ENLocale from '../locales/en.json';
import ESLocale from '../locales/es.json';

import GlobalStyles from './globalStyles';

import Header from './header';
import Footer from './footer';
import Newsletter from '../components/common/Newsletter';
import CookieBanner from '../components/common/CookieBanner';
import Widgets from '../components/widgets';

import StoreContext from '../services/shopify';

import { Wrapper, Main } from './index.styles';
import { defaultLocale, getBrowserLocale } from '../utils';
import usePreviewData from '../utils/usePreviewData';


export const SiteInfos = React.createContext({
  title: '',
  description: '',
  siteUrl: '',
});

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;

const preconnects = [
  'https://images.prismic.io',
  'https://www.googletagmanager.com',
  'https://www.google-analytics.com'
]

const App = ({ children, ...props }) => {
  const { pathname } = useLocation();
  const { pageContext = {}, data: rawData } = props;
  const { locale = 'fr', alternateLocales = [] } = pageContext;
  const {
    headerPosition = 'regular',
    mobileHeaderPosition,
    headerShadow = true
  } = pageContext;

  const resp = useStaticQuery(QUERY);

  const data = usePreviewData({
    ...rawData,
  });

  // useEffect(() => {
  //   const recommendedLocale = getBrowserLocale();

  //   if (pathname !== '/' || recommendedLocale === locale) {
  //     return;
  //   }

  //   const goodPage = alternateLocales.find(p => p.locale === recommendedLocale);

  //   if (!goodPage) {
  //     return;
  //   }
  //   navigate(goodPage.url, { replace: true });
  // }, [pathname, locale, alternateLocales]);

  const baseUrl = resp?.site?.siteMetadata?.siteUrl;

  let localeMessages = {};
  // if (locale === 'en') {
  //   localeMessages = ENLocale;
  // }
  // if (locale === 'es') {
  //   localeMessages = ESLocale;
  // }
  
  console.log('***************', headerPosition, pathname);
  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={localeMessages}
    >
      <Widgets.Provider value={data.prismicWidgets}>
        <SiteInfos.Provider value={resp?.site?.siteMetadata}>
          <Helmet
            htmlAttributes={{
              lang: locale,
            }}
          >
            <link rel="canonical" href={`${baseUrl}${pathname}`} />
            <meta property="og:url" content={`${baseUrl}${pathname}`} />
            {preconnects.map(domain => (
              <link key={domain} rel="preconnect" href={domain} />
            ))}
          </Helmet>
          <StoreContext>
            <Wrapper
              className={classnames({
                regular: headerPosition === 'regular',
                sticky: headerPosition === 'sticky',
                'mobile-sticky': mobileHeaderPosition === 'sticky'
              })}
            >
              <GlobalStyles />
              {pathname !== '/' && (
                <Header
                  position={headerPosition}
                  displayShadow={headerShadow}
                  alternateLocales={alternateLocales}
                />
              )}
              <Main>{children}</Main>
              {pathname !== '/' && (
                <Footer />
              )}
            </Wrapper>
          </StoreContext>
        </SiteInfos.Provider>
      </Widgets.Provider>
    </IntlProvider>
  );
}

const Layout = ({ element, props }) => {
  // Reset gtm ecommerce tracking between screens.
  // If we don't, the ecommerce attr will be passed in all following events if not overrided
  if (typeof window === 'object' && window.dataLayer) {
    window.dataLayer.push({
      ecommerce: undefined,
      productName: undefined,
    });
  }

  return <App {...props}>{element}</App>;
}

export const query = graphql`
  # fragment Menu on PrismicMenu {
  #   data {
  #     body {
  #       ... on PrismicMenuBodyLien {
  #         primary {
  #           link {
  #             url
  #             type
  #             lang
  #             target
  #           }
  #           name
  #           header
  #           footer
  #         }
  #         items {
  #           name
  #           link {
  #             lang
  #             target
  #             type
  #             url
  #           }
  #           is_category
  #           subtitle
  #         }
  #       }
  #     }
  #   }
  # }
  fragment ProductItem on ShopifyProduct {
    availableForSale
    description
    handle
    id
    shopifyId
    productType
    title
    defaultVariant {
      title
      sku
      price
      compareAtPrice
      viscosity
    }
    tags
    thumbnail {
      originalSrc
      id
      # localThumb {
      #   childImageSharp {
      #     fixed(height: 100) {
      #       tracedSVG,
      #       width,
      #       height
      #     }
      #   }
      # }
    }
  }

  fragment CategoryItem on ShopifyCollection {
    id
    title
    handle
    # image {
    #   src
    #   localFile {
    #     childImageSharp {
    #       fixed(quality: 80, width: 310, height: 240, toFormat: JPG, cropFocus: CENTER) {
    #         ...GatsbyImageSharpFixed_withWebp
    #       }
    #     }
    #   }
    # }
  }

  # fragment WidgetConfigurator on PrismicWidgets {
  #   configurator: data {
  #     linkName: configurator_link_name
  #     text: configurator_text
  #     title: configurator_title
  #     brands: configurator_brands {
  #       logo {
  #         url
  #         dimensions {
  #           height
  #           width
  #         }
  #         alt
  #       }
  #     }
  #   }
  # }

  # fragment WidgetPartners on PrismicWidgets {
  #   partners: data {
  #     title: partners_title {
  #       text
  #     }
  #     partners: partners_items {
  #       link {
  #         target
  #         url
  #       }
  #       logo {
  #         url
  #         alt
  #         url
  #         dimensions {
  #           width
  #           height
  #         }
  #       }
  #     }
  #   }
  # }
`

export default Layout;
